<template>

<nav class="bg-white shadow-lg mb-12">
		<div class="max-w-6xl mx-auto px-4">
			<div class="flex justify-between">
				<div class="flex space-x-7">
					<!-- Primary Navbar items -->
					<div class="hidden md:flex items-center space-x-4 font-bold">
						<router-link to="/" class="py-4 px-2 font-sans text-lg hover:text-blue-500 transition duration-300">Compview</router-link>
						<router-link to="/datenbank" class="py-4 px-2 text-gray-500 font-semibold hover:text-blue-500 transition duration-300">Datenbank</router-link>
					</div>
				</div>
			</div>
		</div>
	</nav>

</template>

<script>

export default {
    name: 'Header'
}

</script>

<style scoped>

</style>